<template>
    <div>
        <b-form v-if="is_ready" :class="can_edit_form ? 'edit-outline' : ''">
            <b-row>
                <b-col cols="12" md="12" lg="12">
                    <b-form-group label="Contact Person" label-for="person-input">
                        <b-form-input
                            :state="validateState('contact_person')"
                            v-model="$v.order_details.contact_person.$model"
                            id="person-input"
                            type="text"
                            :disabled="!read_only"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="12" lg="12">
                    <b-form-group label="Contact Number" label-for="contact-input">
                        <b-form-input
                            :state="validateState('contact_no')"
                            v-model="$v.order_details.contact_no.$model"
                            id="contact-input"
                            type="text"
                            :disabled="!read_only"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="12" lg="12">
                    <b-form-group label="Contact Email" label-for="email-input">
                        <b-form-textarea
                            :state="validateState('contact_email')"
                            v-model="$v.order_details.contact_email.$model"
                            id="email-input"
                            type="email"
                            :disabled="!read_only"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="12" lg="12">
                    <b-form-group label="City" label-for="city-input">
                        <b-form-textarea
                            :state="validateState('city')"
                            v-model="$v.order_details.city.$model"
                            :disabled="!read_only"
                            id="city-input"
                            type="text"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                    <b-form-group label="Province" label-for="province-input">
                        <b-form-select
                            :state="validateState('province')"
                            v-model="$v.order_details.province.$model"
                            :disabled="!read_only"
                            id="province-input"
                            :options="client_province_select_options"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                    <b-form-group label="Postal Code" label-for="postal-code-input">
                        <b-form-input
                            :state="validateState('postal_code')"
                            v-model="$v.order_details.postal_code.$model"
                            :disabled="!read_only"
                            id="postal-code-input"
                            type="text"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="12" lg="12">
                    <b-form-group label="Delivery Address" label-for="delivery-address-input">
                        <b-form-textarea
                            :state="validateState('delivery_address')"
                            v-model="$v.order_details.delivery_address.$model"
                            :disabled="!read_only"
                            id="delivery-address-input"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row v-if="read_only" class="mt-4">
                <b-col class="d-flex justify-content-end" cols="12" md="12" lg="12">
                    <b-button @click="saveOrder()" :disabled="changes_made == false" variant="success" pill>
                        <i class="fas fa-save mr-2"></i>
                        Update Details
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
        <b-overlay :show="is_loading" no-wrap></b-overlay>
    </div>
</template>

<script>
import { orderMethods, orderComputed } from "@/state/helpers";
import { cloneDeep } from "lodash";
import { validationMixin } from "vuelidate";
import { required, email, url } from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
    props: {
        order_data: {
            required: true,
        },
        read_only: {
            required: true,
        },
    },
    validations: {
        order_details: {
            contact_person: {
                required,
            },
            contact_no: {
                required,
            },
            delivery_address: {
                required,
            },
            city: {
                required,
            },
            postal_code: {
                required,
            },
            province: {
                required,
            },
            contact_email: {
                required,
                email,
            },
            //notes: {
            //    required,
            //},
            tracking_url: {
                required,
                url,
            },
        },
    },
    data: () => ({
        order_details: {
            order_id: null,
            contact_person: null,
            contact_no: null,
            delivery_address: null,
            city: null,
            postal_code: null,
            province: null,
            contact_email: null,
            notes: null,
            tracking_url: null,
        },
        is_ready: false,
        is_loading: false,
        changes_made: false,
        // countries_select: [
        //     { value: null, text: "Please select an option" },
        //     { value: "eastern_cape", text: "Eastern Cape" },
        //     { value: "free_state", text: "Free State" },
        //     { value: "gauteng", text: "Gauteng" },
        //     { value: "kwazulu_natal", text: "KwaZulu Natal" },
        //     { value: "limpopo", text: "Limpopo" },
        //     { value: "mpumalanga", text: "Mpumalanga" },
        //     { value: "northern_cape", text: "Northern Cape" },
        //     { value: "north_west", text: "North West" },
        //     { value: "western_cape", text: "Western Cape" },
        // ],
    }),
    watch: {
        order_details: {
            deep: true,
            handler() {
                if (this.can_edit_form && this.is_ready && !this.is_loading) {
                    this.changes_made = true;
                }
            },
        },
    },
    computed: {
        ...orderComputed,
        can_edit_form() {
            return !this.is_order_readonly;
        },
        client_province_select_options() {
            // need to confirm the details here with api config

            return [
                {
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                { value: "Western Cape", text: "Western Cape" },
                { value: "Eastern Cape", text: "Eastern Cape" },
                { value: "Free State", text: "Free State" },
                { value: "Gauteng", text: "Gauteng" },
                { value: "KwaZulu-Natal", text: "KwaZulu-Natal" },
                { value: "Limpopo", text: "Limpopo" },
                { value: "Mpumalanga", text: "Mpumalanga" },
                { value: "North-West", text: "North-West" },
                { value: "Northern-Cape", text: "Northern-Cape" },
            ];
        },
    },
    methods: {
        ...orderMethods,
        openLink() {
            if (this.order_details.tracking_url != null) {
                window.open(this.order_details.tracking_url, "_blank");
            } else {
                this.$swal.fire("Link not available");
            }
        },
        initOrderDetailsData() {
            // * used clone deep to allow editing
            if (this.can_edit_form) {
                this.order_details = cloneDeep(this.order_data);
            } else {
                this.order_details = this.order_data;
            }
            this.$nextTick(() => {
                this.is_ready = true;
            });
        },
        validateState(name) {
            if (this.is_ready && this.$v.order_details != undefined) {
                const { $dirty, $error } = this.$v.order_details[name];
                return $dirty ? !$error : null;
            }
        },
        saveOrder() {
            this.$v.order_details.$touch();
            if (this.$v.order_details.$anyError) {
                return;
            }

            let params = {
                order_id: this.order_details.id,
                contact_person: this.order_details.contact_person,
                contact_no: this.order_details.contact_no,
                delivery_address: this.order_details.delivery_address,
                city: this.order_details.city,
                postal_code: this.order_details.postal_code,
                province: this.order_details.province,
                contact_email: this.order_details.contact_email,
                notes: this.order_details.notes,
                courier_url: this.order_details.tracking_url,
                properties: [],
                products: [], //* {"id":3,"qty":5}
            };
            params.products = this.order_details.products.map((product) => {
                return {
                    id: product.pivot.product_id,
                    qty: product.pivot.quantity,
                };
            });
            //eslint-disable-next-line
            this.is_loading = true;
            this.updateOrder(params)
                .then(() => {
                    this.changes_made = false;
                    this.getOrderDetails().then(() => {
                        this.initOrderDetailsData();
                        this.$nextTick(() => {
                            this.is_loading = false;
                            this.$v.$reset();
                            this.$swal.fire({
                                icon: "success",
                                title: "Updated order details",
                                toast: true,
                                position: "top",
                                showConfirmButton: false,
                                timer: 3500,
                                timerProgressBar: true,
                            });
                        });
                    });
                })
                .catch(() => {
                    this.$swal.fire("Failed to update order details");
                    this.is_loading = false;
                });
        },
    },
    mounted() {
        this.initOrderDetailsData();
    },
};
</script>

<style>
.edit-outline .form-control:disabled {
    border: 1px solid #eceff2;
}
.edit-outline .form-control {
    /*border: 1px solid #64ed3a !important;  #34c38f*/
    border: 1px solid #556ee6;
}

.edit-outline .custom-select:disabled {
    border: 1px solid #eceff2;
}
.edit-outline .custom-select {
    /*border: 1px solid #64ed3a !important;  #34c38f*/
    border: 1px solid #556ee6;
}
</style>

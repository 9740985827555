<template>
    <div>
        <b-card class="border border-primary">
            <b-row class="d-flex justify-content-end mr-5 center-certain-width">
                <div class="show-instead-of-progress-bar center-certain-width">
                    <b-button size="lg" :style="shouldBlockClick" :variant="returnButtonStatus(0)" pill class="btn-warning text-center">
                        <!-- {{ selected_order_details.status_id }} -->
                        <!-- prettier-ignore -->
                        <span >Status:&nbsp;{{returnStatusName}}</span>
                    </b-button>
                </div>
            </b-row>
            <b-row v-if="read_only == false" class="d-flex justify-content-between center-certain-width">
                <div class="ml-5 center-certain-width">
                    <h4 class="mt-2 mb-2">Manage Order</h4>
                    <img src="@/assets/images/darkroots_web.webp" alt height="150" />
                </div>
                <div class="mr-5 center-certain-width">
                    <h4 class="mt-2">
                        <div class="mb-2">
                            Order No: <span style="text-decoration: underline">{{ order_data.order_no }}</span>
                        </div>
                        <div class="mb-2">
                            Due Date: <span style="text-decoration: underline">{{ getDueDate }}</span>
                        </div>
                    </h4>
                </div>
            </b-row>

            <!-- <b-col class="text-center">
                <img src="@/assets/images/darkroots_web.webp" alt height="150" />
            </b-col> -->

            <b-overlay :show="is_saving">
                <b-card-body>
                    <hr />
                    <b-row>
                        <b-col cols="12" sm="12" md="12" lg="12">
                            <clientDetails :order_data="order_data" />
                        </b-col>
                    </b-row>
                    <hr />

                    <!-- <div style="position: relative" class="test"> -->
                    <div style="position: relative" class="hide-progress-bar float-center">
                        <b-button
                            @click="updateOrderStatus(1)"
                            size="lg"
                            :style="shouldBlockClick"
                            style="position: absolute; top: 7px"
                            :variant="returnButtonStatus(0)"
                            pill
                            class=""
                        >
                            Recieved
                        </b-button>
                        <b-button
                            @click="updateOrderStatus(2)"
                            size="lg"
                            :style="shouldBlockClick"
                            style="position: absolute; right: 75%; top: 7px"
                            :variant="returnButtonStatus(25)"
                            pill
                        >
                            Confirmed
                        </b-button>
                        <b-button
                            @click="updateOrderStatus(3)"
                            size="lg"
                            :style="shouldBlockClick"
                            style="position: absolute; right: 50%; top: 7px"
                            :variant="returnButtonStatus(50)"
                            pill
                        >
                            Processing
                        </b-button>
                        <b-button
                            @click="updateOrderStatus(4)"
                            size="lg"
                            :style="shouldBlockClick"
                            style="position: absolute; right: 25%; top: 7px"
                            :variant="returnButtonStatus(75)"
                            pill
                        >
                            Awaiting Collection
                        </b-button>
                        <b-button
                            @click="updateOrderStatus(5)"
                            size="lg"
                            :style="shouldBlockClick"
                            style="position: absolute; right: 0; top: 7px"
                            :variant="returnButtonStatus(100)"
                            pill
                        >
                            Dispatched
                        </b-button>
                        <div class="pt-4">
                            <div class="progress mb-4">
                                <div
                                    class="pt-2 progress-bar progress-bar-animated progress-bar-striped bg-success"
                                    role="progressbar"
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    :style="progress_style"
                                ></div>
                            </div>
                        </div>
                    </div>

                    <div class="show-instead-of-progress-bar">
                        <b-button
                            class="mb-2 mw-100"
                            @click="updateOrderStatus(1)"
                            size="md"
                            :style="shouldBlockClick"
                            :variant="returnButtonStatus(0)"
                            pill
                        >
                            1. Recieved Order
                        </b-button>
                        <b-button
                            class="mb-2 mw-100"
                            @click="updateOrderStatus(2)"
                            size="md"
                            :style="shouldBlockClick"
                            :variant="returnButtonStatus(25)"
                            pill
                        >
                            2. Confirmed Order
                        </b-button>
                        <b-button
                            class="mb-2 mw-100"
                            @click="updateOrderStatus(3)"
                            size="md"
                            :style="shouldBlockClick"
                            :variant="returnButtonStatus(50)"
                            pill
                        >
                            3. Processing
                        </b-button>
                        <b-button
                            class="mb-2 mw-100"
                            @click="updateOrderStatus(4)"
                            size="md"
                            :style="shouldBlockClick"
                            :variant="returnButtonStatus(75)"
                            pill
                        >
                            4. Awaiting Collection
                        </b-button>
                        <b-button
                            class="mb-2 mw-100"
                            @click="updateOrderStatus(5)"
                            size="md"
                            :style="shouldBlockClick"
                            :variant="returnButtonStatus(100)"
                            pill
                        >
                            5. Dispatched
                        </b-button>
                    </div>
                </b-card-body>
            </b-overlay>
        </b-card>

        <b-row>
            <b-col cols="12">
                <!-- <b-card title="Orders"> -->
                <ordersTable :order_data="order_data" :read_only="!read_only" />
            </b-col>
        </b-row>

        <!-- <b-row>
            <b-col>
                <b-card title="Links">
                    <b-card-body class="d-flex">
                        <downloads :read_only="read_only" />
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row> -->

        <b-row>
            <!-- On extra-small and small screens, the courier details will take the full width (12 columns).
           On medium and larger screens, it will take half of the available width (6 columns). -->
            <b-col cols="12" sm="12" md="6" lg="6">
                <b-card title="Courier details">
                    <orderDetails :order_data="order_data" :read_only="!read_only" />
                </b-card>
            </b-col>

            <!-- On extra-small, small, and medium screens, the "Display Material" card will take the full width (12 columns).
           On large and extra-large screens, it will take one-third of the available width (4 columns). -->
            <!---<b-col cols="12" sm="12" md="6" lg="5">
                <b-card title="Display Material" id="tooltip-target-1">
                    <b-card-body>
                        <properties :properties="order_data.properties" />
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- On extra-small, small, and medium screens, the "Links" card will take the full width (12 columns).
           On large and extra-large screens, it will take one-third of the available width (4 columns). -->
            <b-col cols="12" sm="12" md="6" lg="6">
                <b-card title="Links">
                    <b-card-body>
                        <downloads :read_only="read_only" />
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
//eslint-disable-next-line
import { parseISO, format } from "date-fns";
import _ from "lodash";
import { orderMethods, orderComputed } from "@/state/helpers";
import ordersTable from "./orders-bootstrap-table.vue";
import orderDetails from "./order/order-details.vue";
import downloads from "./order/downloads.vue";
//import properties from "./order/properties.vue";
import clientDetails from "./order/client-details.vue";

export default {
    props: {
        read_only: {
            required: true,
        },
        order_data: {
            required: true,
        },
    },
    components: {
        ordersTable,
        orderDetails,
        downloads,
        //properties,
        clientDetails,
    },
    data: () => ({
        progress_style: "width: 0%;",
        progress_value: 0,
        is_saving: false,
    }),
    computed: {
        ...orderComputed,
        getDueDate() {
            if (this.order_data) {
                return format(parseISO(this.order_data.due_date), "do MMMM yyyy");
            } else {
                return "";
            }
        },
        shouldBlockClick() {
            if (this.read_only) {
                return "pointer-events: none;";
            } else {
                return "";
            }
        },
        returnStatusName() {
            if (this.order_data.status_id == null) {
                return;
            }
            // return this.order_data.status_id;
            let id = this.order_data.status_id;

            if (id == 1) return "Received";
            if (id == 2) return "Confirmed";
            if (id == 3) return "Processing";
            if (id == 4) return "Awaiting Collection";
            if (id == 5) return "Dispatched";

            return [];
        },
    },
    methods: {
        ...orderMethods,
        // handleBack() {
        //     this.setSelectedOrder(-1);
        // },
        returnButtonStatus(value) {
            if (value <= this.progress_value) {
                return "primary";
            } else {
                return "light";
            }
        },
        setStatusDetails() {
            //* set the progress bar width
            let active_status = _.find(this.order_data.status, {
                active: true,
            });
            if (active_status) {
                this.progress_value = (active_status.id - 1) * 25;
                this.progress_style = "width:" + this.progress_value + "%;";
            }
        },
        updateOrderStatus(status_id) {
            let new_status = _.find(this.order_data.status, { id: status_id });
            let old_status = _.find(this.order_data.status, {
                id: this.order_data.status_id,
            });
            if (new_status.order <= old_status.order) {
                return;
            }
            this.$swal
                .fire({
                    title: "Update Order Status",
                    text: "Change order status to: " + new_status.name.toUpperCase(),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                })
                .then((result) => {
                    if (result.value) {
                        this.is_saving = true;
                        let params = {
                            order_id: this.order_data.id,
                            status_id: status_id,
                        };
                        if (this.read_only) {
                            this.updateExternalOrderStatus(params).then(() => {
                                this.getOrderDetails(this.order_data.id).then(() => {
                                    this.setStatusDetails();
                                    this.is_saving = false;
                                    this.$swal.fire({
                                        title: "Error",
                                        text: "Failed to update status",
                                        icon: "warning",
                                    });
                                });
                            });
                        } else {
                            this.updateExternalOrderStatus(params)
                                .then(() => {
                                    this.getOrderDetails(this.order_data.id).then(() => {
                                        this.setStatusDetails();
                                        this.is_saving = false;
                                    });
                                })
                                .catch(() => {
                                    this.is_saving = false;
                                    this.$swal.fire({
                                        title: "Error",
                                        text: "Failed to update status",
                                        icon: "warning",
                                    });
                                });
                        }
                    }
                });
        },
    },
    mounted() {
        this.setStatusDetails();
    },
    beforeDestroy() {
        if (this.read_only == false) {
            // this.handleBack();
        }
    },
};
</script>

<style lang="scss" scoped>
.show-instead-of-progress-bar {
    display: none;
}

@media only screen and (max-width: 1350px) {
    .hide-progress-bar {
        display: none;
    }
    .show-instead-of-progress-bar {
        display: unset;
    }
}
@media only screen and (max-width: 590px) {
    .center-certain-width {
        //
        display: flex;

        width: 100%;
        align-items: center;
        text-align: center;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}
</style>

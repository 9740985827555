<template>
    <div>
        <b-form>
            <b-row>
                <b-col cols="12" sm="6" md="4" lg="3">
                    <b-form-group label="Company Name" label-for="person-input">
                        <b-form-input size="sm" v-model="orderInfo.client.company_name" disabled id="company_name" type="text"></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="6" md="4" lg="3">
                    <b-form-group label="Contact Person" label-for="contact-input">
                        <b-form-input size="sm" v-model="orderInfo.contact_person" disabled id="contact_person" type="text"></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="6" md="4" lg="2">
                    <b-form-group label="Contact No" label-for="contact-input">
                        <b-form-input size="sm" v-model="orderInfo.contact_no" disabled id="contact_no" type="text"></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="6" md="4" lg="2">
                    <b-form-group label="Company Reg" label-for="contact-input">
                        <b-form-input size="sm" v-model="orderInfo.client.company_reg" disabled id="company_reg" type="number"></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="6" md="4" lg="2">
                    <b-form-group label="Vat No" label-for="contact-input">
                        <b-form-input size="sm" v-model="orderInfo.client.vat_no" disabled id="vat_no" type="number"></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="6" md="6" lg="5">
                    <b-form-group label="Address" label-for="contact-input">
                        <b-form-textarea size="sm" v-model="orderInfo.delivery_address" disabled id="address" type="text"></b-form-textarea>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="6" md="6" lg="5">
                    <b-form-group label="Emails" label-for="">
                        <b-form-textarea size="sm" v-model="orderInfo.client.emails" disabled id="emails" type="email"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import _ from 'lodash';
import { orderComputed } from "@/state/helpers";
export default {
    props: {
        order_data: {
            required: true,
        },
    },
    data: () => ({
        orderInfo: {}
    }),
    computed: {
        ...orderComputed,
    },
    methods: {},
    created() {
        this.orderInfo = _.cloneDeep(this.order_data);
    },
};
</script>

<style scoped>
.form-control:disabled {
    border: 1px solid #eceff2 !important;
}
</style>
